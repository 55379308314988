import React, { useState, useEffect } from "react";
import Header from "./components/Header/Header";
import Items from "./components/Items/Items";
import Footer from "./components/Footer/Footer";

const App = () => {
  const [data, setData] = useState(null);
  const [items, setItems] = useState([]);

  useEffect(() => {
    // Define the API endpoint you want to call
    const apiUrl = "https://israelprice.com/api/items";

    // Define headers
    const headers = new Headers({
      "Content-Type": "application/json",
    });

    // Make the API call with headers
    fetch(apiUrl, { headers })
      .then((response) => response.json())
      .then((result) => {
        console.log(result);

        // Transform the data into the items format
        const transformedItems = result.map((item) => ({
          id: item.id,
          title: item.title,
          img: item.img,
          desc: item.desc,
          category: item.category,
          tarif: item.tarif,
        }));

        // Update state with the transformed data
        setItems(transformedItems);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const [filter, setFilter] = useState("all");

  const getFilteredItems = () => {
    if (filter === "all") {
      return items;
    }
    return items.filter((item) => item.category === filter);
  };

  const filteredItems = getFilteredItems();

  return (
    <div className="wrapper">
      <Header setFilter={setFilter} />
      <Items items={filteredItems} />
      <Footer />
    </div>
  );
};

export default App;
