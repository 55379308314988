import React, { Component } from "react";
import Item from "../Item/Item";

export default class Items extends Component {
  render() {
    const { items } = this.props; // Деструктурируем items из props для более чистого доступа

    return (
      <main>
        {items.map((el) => (
          <Item key={el.id} item={el} />
        ))}
      </main>
    );
  }
}
