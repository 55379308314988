import React, { useState } from "react";
import "./Item.css";

const Item = ({ item }) => {
  const [isFeedbackFormVisible, setFeedbackFormVisible] = useState(false);

  const toggleFeedbackForm = () => {
    setFeedbackFormVisible((prevState) => !prevState.isFeedbackFormVisible);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Обработка данных формы
    // ...
  };

  const { img, title, desc, tarif } = item;

  return (
    <div
      className="item"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <div
        className="ImageContainer"
        style={{
          width: "250px",
          height: "250px",
        }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/img/${img}`}
          alt={title}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </div>

      <h2>{title}</h2>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <p>{desc}</p>
        <b>{tarif}₪</b>
        <div className="add-to-cart" onClick={toggleFeedbackForm}>
          +
        </div>
      </div>

      {isFeedbackFormVisible && (
        <form onSubmit={handleSubmit} className="feedBackForm">
          <input type="text" name="name" placeholder="Ваше имя" required />
          <input type="tel" name="phone" placeholder="Ваш телефон" required />
          <button type="submit">Отправить</button>
        </form>
      )}
    </div>
  );
};

export default Item;
